import { Modal, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Constant/Spinner/Spinnner";
import "./addDegree.scss";
import { useNavigate } from "react-router-dom";
import {
  addCompetency,
  editCompetency,
  getCompetencies,
} from "../../module/features/competency";
import { addDegree, getDegree, updateDegree } from "../../module/features/Degree";

function AddDegreeModal({ isModalOpen, handleOk, handleCancel, editState }) {
  const courses = useSelector((state) => state.coursesSlice.courses);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name:""
  });
  const [loader, setLoader] = useState(false);
  const [coursesArr, setCoursesArr] = useState([]);
  useEffect(() => {
    if (courses?.length) {
      let arr = courses.map((a, i) => {
        return {
          ...a,
          value: a.id,
          label: a.course,
        };
      });
      setCoursesArr(arr);
    }
  }, [courses]);
  const clearState = () => {
    setData({
      name:""
    });
  };
  const callback = () => {
    clearState();
    handleOk();
    setLoader(false);
    dispatch(getDegree());
  };
  const addDegreeFun = () => {
    setLoader(true);
    dispatch(addDegree({ data: data, callback }));
  };
  useEffect(() => {
    if (editState) {
      setData({
        // "course_id": editState.course_id,
        name: editState.name
      });
    } else {
      clearState();
    }
  }, [editState]);
  const editCompetencyFun = () => {
    setLoader(true);
    dispatch(updateDegree({ data: data, id: editState.id, callback }));
  };

  return (
    <Modal
      className="addCompetencyModal"
      title={editState ? "Edit Degree" : "Add Degree"}
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        clearState();
      }}
    >
      <div className="form">
        <label
          className="addCourseInput questionInput"
          style={{ width: "100%" }}
        >
          <p>Degree Name</p>
          <input
            style={{ width: "100%" }}
            placeholder="Degree Name"
            value={data.name}
            onChange={(ev) => {
              let obj = { ...data };
              obj.name = ev.target.value;
              setData(obj);
            }}
          />
        </label>
        <div className="addCourse">
          <button className="addCompetencyButton cancel" onClick={handleCancel}>
            Cancel
          </button>
          {editState ? (
            <button
              className="addCompetencyButton add"
              onClick={editCompetencyFun}
            >
              {loader ? <Spinner /> : `Edit Degree`}
            </button>
          ) : (
            <button
              className="addCompetencyButton add"
              onClick={addDegreeFun}
            >
              {loader ? <Spinner /> : `Add Degree`}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AddDegreeModal;
