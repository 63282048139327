import React, { useState } from "react";
import loginGIF from "./../../Assest/loginGIF.json";
import { Link, useNavigate } from "react-router-dom";
import "./Authentication.scss";
import { useLottie } from "lottie-react";
import logo from "./../../Assest/logo.png";
import { useDispatch } from "react-redux";
import { register } from "../../module/features/authentication";
import SocialLogin from "./SocialLogin";
import { EyeClosed, EyeOpen } from "../../Constant/Icons";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const url = new URL(window.location);
  const [data, setData] = useState({
    username: "",
    password: "",
    email: "",
    type: "",
  });
  const options = {
    animationData: loginGIF,
    loop: true,
  };

  const { View } = useLottie(options);

  const clearState = () => {
    setData({
      username: "",
      password: "",
      email: "",
      type: "",
    });
    const url = new URL(window.location);
    const redirect = url.searchParams.get("redirect");
    if (redirect) {
      navigate(`/login?redirect=${redirect}`);
    } else {
      navigate(`/login`);
    }
  };

  const registerUser = () => {
    const type = url.searchParams.get("type");
    let obj = {
      ...data,
      type: type ? type : "student",
    };
    dispatch(register({ data: obj, clearState: clearState, setLoader }));
  };
  return (
    <div className="authenticationPage">
      <div className="innerAuthenticationPage">
        <div className="gifBoxDiv">
          <div className="gifBox">{View}</div>
        </div>
        <div className="form">
          <div className="formDIv">
            <div>
              <h3>Register</h3>
            </div>
            <div className="innerForm">
              <label>
                <p>User Name</p>
                <input
                  value={data.username}
                  placeholder="User Name"
                  onChange={(ev) => {
                    let obj = { ...data };
                    obj.username = ev.target.value;
                    setData(obj);
                  }}
                />
              </label>
              <label>
                <p>Email</p>
                <input
                  value={data.email}
                  placeholder="Email"
                  onChange={(ev) => {
                    let obj = { ...data };
                    obj.email = ev.target.value;
                    setData(obj);
                  }}
                />
              </label>
              <label>
                <p>Password</p>
                <div className="passwordInput">
                  <input
                    type={passwordInputType}
                    value={data.password}
                    placeholder="Password"
                    onChange={(ev) => {
                      let obj = { ...data };
                      obj.password = ev.target.value;
                      setData(obj);
                    }}
                    onKeyDown={(ev) => {
                      if (ev.keyCode === 13) {
                        registerUser();
                      }
                    }}
                  />
                  <button
                    className="showPassword"
                    onClick={() => {
                      if (passwordInputType === "password") {
                        setPasswordInputType("text");
                      } else {
                        setPasswordInputType("password");
                      }
                    }}
                  >
                    {passwordInputType === "password" ? (
                      <EyeOpen />
                    ) : (
                      <EyeClosed />
                    )}
                  </button>
                </div>
              </label>

              <div className="loginButtons">
                <button
                  disabled={true}
                  className="hoverEffect"
                  onClick={registerUser}
                >
                  Register
                </button>
                <p>
                  Already have an account? <Link to={"/login"}>Login Now</Link>
                </p>
              </div>
            </div>
            {/* <SocialLogin type="student" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
