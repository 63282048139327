import { configureStore } from '@reduxjs/toolkit'
import Authentication from './features/authentication'
import completeProfileSlice from './features/completeProfile'
import coursesSlice from './features/courses'
import competencySlice from './features/competency'
import assessmentSlice from './features/assesments'
import getUsersSlice from './features/users'
import occupationalTodo from './features/occupational'
import professorSlice from './features/professor'
import universitySlice from './features/university'
import ratingSlice from './features/ratings'
import outcomesSlice from './features/outcomesSlice'
import professorCourseSlice from './features/ProfessorCourse'
import DegreeSlice from './features/Degree'
import ConcentrationSlice from './features/concentration'
export const store = configureStore({
    reducer: {
        Authentication: Authentication,
        completeProfileSlice: completeProfileSlice,
        coursesSlice: coursesSlice,
        competencySlice: competencySlice,
        ConcentrationSlice: ConcentrationSlice,
        degreeSlice: DegreeSlice,
        assessmentSlice: assessmentSlice,
        getUsersSlice: getUsersSlice,
        occupationalTodo: occupationalTodo,
        professorSlice: professorSlice,
        universitySlice: universitySlice,
        ratingSlice: ratingSlice,
        outcomesSlice: outcomesSlice,
        professorCourseSlice: professorCourseSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})