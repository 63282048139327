import {
  Popconfirm,
  Progress,
  Select,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useRef } from "react";
import AddDegreeModal from "./AddDegreeModal";
import { useState } from "react";
import {
  deleteCompetency,
  getCompetencies,
  getCoursesCompetencies,
  updateTierAndVisiblityCompetency,
} from "../../module/features/competency";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AddOutcomeIcon,
  DeleteIcon,
  EditIcon,
  EyeClosed,
  EyeOpen,
} from "../../Constant/Icons";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
import Column from "antd/es/table/Column";

import { getOutcomeById } from "../../module/features/outcomesSlice";
import { getDegree } from "../../module/features/Degree";

function AddDegree() {
  const navigate = useNavigate();
  const url = new URL(window.location);
  const id = url.searchParams.get("id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const degrees = useSelector(
    (state) => state.degreeSlice.degrees
  );


  const dispatch = useDispatch();

  const showModal = (a) => {
    setIsModalOpen(true);
    setEditState(a);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setEditState(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditState(false);
  };


  const callback = () => {
    dispatch(getDegree());
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (confirm) {
      confirm();
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const items = [
    {
      label: "1st Tier",
      key: "0",
    },
    {
      label: "2nd Tier",
      key: "1",
    },
    {
      label: "3rd Tier",
      key: "3",
    },
  ];

  useEffect(() => {
    dispatch(getDegree());
  }, []);



  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            console.log(
              selectedKeys,
              confirm,
              dataIndex,
              "selectedKeys, confirm, dataIndex"
            );
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              console.log(selectedKeys, confirm, dataIndex, "selectedKeys");
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    )
  });


  return (
    <div className="AddCompetency">
      <div className="innerAddCompetency">
        <div className="PageHeading">
          <h1>Degree</h1>
          <button className="hoverEffect" onClick={() => showModal(false)}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path>
              <path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path>
            </svg>
            Add Degree
          </button>
        </div>
        <div className="coursesTable ">
          <div className="coursesTableDiv">
            <Table dataSource={degrees}>
              <Column
                width="90%"
                filterDropdown={getColumnSearchProps("name").filterDropdown}
                filterIcon={getColumnSearchProps("name").filterIcon}
                onFilter={getColumnSearchProps("name").onFilter}
                onFilterDropdownOpenChange={
                  getColumnSearchProps("name").onFilterDropdownOpenChange
                }
                title="Degree Name"
                className="tdDiv title"
                dataIndex="name"
                key="name"
                render={getColumnSearchProps("name").render}
              />

              <Column
                title="Actions"
                render={(text, record, index) => (
                  <div className="actionButtons">

                    <button
                      className="editButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        showModal(record);
                      }}
                    >
                      <EditIcon />
                    </button>
                  </div>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
      <AddDegreeModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        editState={editState}
      />
    </div>
  );
}

export default AddDegree;
