import { Checkbox, descriptions, Modal, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCourse,
  editCourse,
  getCourse,
} from "../../module/features/courses";
import Spinner from "../../Constant/Spinner/Spinnner";
import { useEffect } from "react";
import { getDegree } from "../../module/features/Degree";
import { getConcentration } from "../../module/features/concentration";
import TextArea from "antd/es/input/TextArea";

function AddCourseModal({
  showModal,
  handleOk,
  handleCancel,
  isModalOpen,
  setIsModalOpen,
  editState,
}) {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.coursesSlice.loader);
  const concentration = useSelector(
    (state) => state.ConcentrationSlice.concentration
  );
  const competencies = useSelector(
    (state) => state.competencySlice.competencies
  )?.map((a, i) => {
    return {
      ...a,
      value: a.id,
      label: a.name,
    };
  });
  useEffect(() => {
    dispatch(getConcentration());
  }, []);

  const [concentrationArr, setconcentrationArr] = useState([]);
  useEffect(() => {
    console.log(concentration, "concentration");
    if (concentration?.length) {
      let arr = concentration.map((a, i) => {
        return {
          ...a,
          value: a.id,
          label: a.name,
        };
      });
      console.log(arr, "dasdasdasd");
      setconcentrationArr(arr);
    }
  }, [concentration]);
  useEffect(() => {
    console.log(competencies, "competenciescompetencies");
  }, [competencies]);

  const [data, setData] = useState({
    course: "",
    degree: "",
    description: "",
    course_code: "",
    concentration_id: "",
    competency: [],
    display: true,
  });
  const clearState = () => {
    setData({
      course: "",
      degree: "",
      description: "",
      course_code: "",
      concentration_id: "",
      competency: [],
      display: true,
    });
  };
  const callback = () => {
    clearState();
    dispatch(getCourse());
    handleOk();
  };
  const addCourseFn = () => {
    dispatch(addCourse({ data: data, callback: callback }));
  };

  const editCourseFn = () => {
    dispatch(editCourse({ data: data, id: editState.id, callback: callback }));
  };

  useEffect(() => {
    if (editState) {
      setData({
        course_code: editState.course_code,
        course: editState.course,
        description: editState.description,
        degree: editState.degree,
        concentration_id: editState.concentration_id,
        competency: editState?.course_competencies?.map((a, i) => {
          return a.competencies?.id;
        }),
      });
    } else {
      clearState();
    }
  }, [editState]);

  return (
    <Modal
      title="Add Courses"
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        clearState();
      }}
    >
      <div className="coursesTableDiv">
        <div>
          <div className="form">
            <label className="addCourseInput">
              <p>Course Name</p>
              {/* <div className='addCourse'> */}
              <input
                style={{ width: "100%" }}
                placeholder="Course Name"
                value={data.course}
                // value={data.fullname}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.course = ev.target.value;
                  setData(obj);
                }}
              />
              {/* <button className='hoverEffect'>Add</button>
                </div> */}
            </label>
            <label className="addCourseInput">
              <p>Course Level</p>
              {/* <div className='addCourse'> */}
              <Select
                showSearch
                style={{
                  width: 200,
                }}
                placeholder="Select Level"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={data.degree}
                options={[
                  {
                    value: "Graduate",
                    label: "Graduate",
                  },
                  {
                    value: "Undergraduate",
                    label: "Under Graduate",
                  },
                ]}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.degree = ev;
                  setData(obj);
                }}
              />
              {/* <button className='hoverEffect'>Add</button>
                </div> */}
            </label>
            <label className="addCourseInput">
              <p>Select Competencies</p>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                value={data.competency}
                placeholder="Please select"
                onChange={(ev) => {
                  console.log(ev, "sadasdsadas");
                  let obj = { ...data };
                  obj.competency = ev;
                  setData(obj);
                }}
                options={competencies}
              />
            </label>
            <label className="addCourseInput">
              <p>Course Code</p>
              <input
                style={{ width: "100%" }}
                placeholder="Enter Course Code"
                value={data.course_code}
                // value={data.fullname}
                onChange={(ev) => {
                  let obj = { ...data };
                  obj.course_code = ev.target.value;
                  setData(obj);
                }}
              />
            </label>
            <label className="addCourseInput" style={{ width: "100%" }}>
              <p>Compulsory</p>
              <Checkbox
                onChange={(ev) => {
                  console.log(ev.target.checked);
                }}
              />
            </label>
            <hr />
            <label className="addCourseInput">
              <p>Concentration</p>
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                value={data.concentration_id}
                placeholder="Please select"
                onChange={(ev) => {
                  console.log(ev, "sadasdsadas");
                  let obj = { ...data };
                  obj.concentration_id = ev;
                  setData(obj);
                }}
                options={concentrationArr}
              />
            </label>
          </div>
          <label
            className="addCourseInput"
            style={{
              width: "100%",
              fontSize: "20px",
              fontWeight: " 600",
              color: "rgba(0, 0, 0, 0.4705882353)",
            }}
          >
            <p>Description</p>
            <TextArea
              allowClear
              style={{
                width: "100%",
                height: "100px",
                marginBottom: 20,
              }}
              value={data.description}
              placeholder="Course description"
              onChange={(ev) => {
                console.log(ev, "sadasdsadas");
                let obj = { ...data };
                obj.description = ev.target.value;
                setData(obj);
              }}
              options={concentrationArr}
            />
          </label>

          <div className="addCourse">
            {editState ? (
              <button className="hoverEffect" onClick={editCourseFn}>
                {loader ? <Spinner /> : `Edit Course`}
              </button>
            ) : (
              <button className="hoverEffect" onClick={addCourseFn}>
                {loader ? <Spinner /> : `Add Course`}
              </button>
            )}
          </div>
        </div>
        {/* <div className='addCourseList'>

                    <table className='coursesTable'>
                        <thead>
                            <tr>
                                <th className='title'>Course Title</th>
                                <th className='status'>Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses?.map((a,i) => (
                                <tr>
                                    <td>
                                        <div>
                                            {a.course}
                                        </div>
                                    </td>
                                    <td>
                                        {a.degree}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}
      </div>
    </Modal>
  );
}

export default AddCourseModal;
