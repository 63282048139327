import {
  Popconfirm,
  Progress,
  Select,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useRef } from "react";
import AddCompentencyModal from "./AddCompentencyModal";
import { useState } from "react";
import {
  deleteCompetency,
  getCompetencies,
  getCoursesCompetencies,
  updateTierAndVisiblityCompetency,
} from "../../module/features/competency";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotAdded from "../../Constant/NotAdded";
import {
  AddOutcomeIcon,
  DeleteIcon,
  EditIcon,
  EyeClosed,
  EyeOpen,
} from "../../Constant/Icons";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import AddOutcomeModal from "./AddOutcomeModal";
import { getOutcomeById } from "../../module/features/outcomesSlice";

function AddCompetency() {
  const navigate = useNavigate();
  const url = new URL(window.location);
  const id = url.searchParams.get("id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOutcomeOpen, setIsModalOutcomeOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const competencies = useSelector(
    (state) => state.competencySlice.competencies
  )?.map((a, i) => {
    console.log(a, "dada");

    return {
      ...a,
      // courseName: a?.course_competencies.map(({ course }) => course.course),
      // degree: a?.course_competencies.map(({ course }) => course.degree),
    };
  });
  const singleCourseCompetency = useSelector(
    (state) => state.competencySlice.singleCourseCompetency
  );

  const dispatch = useDispatch();

  const showModal = (a) => {
    setIsModalOpen(true);
    setEditState(a);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setEditState(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditState(false);
  };

  const showModalOutcome = (a) => {
    console.log(a);
    if (a.outcome_id) {
      dispatch(
        getOutcomeById({
          ids: a.outcome_id,
        })
      );
    }
    setIsModalOutcomeOpen(true);
    setEditState(a);
  };
  const handleOkOutcome = () => {
    setIsModalOutcomeOpen(false);
    setEditState(false);
  };
  const handleCancelOutcome = () => {
    setIsModalOutcomeOpen(false);
    setEditState(false);
  };

  const callback = () => {
    dispatch(getCompetencies());
  };
  const confirm = (data) => {
    dispatch(deleteCompetency({ id: data.id, callback }));
  };
  const cancel = (e) => {
    console.log(e);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    if (confirm) {
      confirm();
    }
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const items = [
    {
      label: "1st Tier",
      key: "0",
    },
    {
      label: "2nd Tier",
      key: "1",
    },
    {
      label: "3rd Tier",
      key: "3",
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getCoursesCompetencies({ id: id }));
    }
  }, [id]);
  // useEffect(()=>{

  // },[])
  const [courseCompetencies, setCourseCompetencies] = useState([]);
  useEffect(() => {
    if (id) {
      let arr = singleCourseCompetency.map((a, i) => {
        return {
          ...a,
          name: a.name,
          tier: a.tier,
          hidden: a.hidden,
        };
      });
      setCourseCompetencies(arr);
      // console.log(singleCourseCompetency,'singleCourseCompetency')
    } else {
      setCourseCompetencies([]);
    }
  }, [singleCourseCompetency]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            console.log(
              selectedKeys,
              confirm,
              dataIndex,
              "selectedKeys, confirm, dataIndex"
            );
            handleSearch(selectedKeys, confirm, dataIndex);
          }}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              console.log(selectedKeys, confirm, dataIndex, "selectedKeys");
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch([], confirm, dataIndex);
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : dataIndex === "onnet_competency" ? (
        text ? (
          "O*Net"
        ) : (
          "Manual"
        )
      ) : (
        text
      ),
  });
  const updateTierAndVisiblityCompetencyFn = (data, id) => {
    dispatch(
      updateTierAndVisiblityCompetency({ data: data, id: id, callback })
    );
  };

  return (
    <div className="AddCompetency">
      <div className="innerAddCompetency">
        <div className="PageHeading">
          <h1>Competencies</h1>
          <button className="hoverEffect" onClick={() => showModal(false)}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8Z"></path>
              <path d="M192 474h672q8 0 8 8v60q0 8-8 8H160q-8 0-8-8v-60q0-8 8-8Z"></path>
            </svg>
            Add Competency
          </button>
        </div>
        <div className="coursesTable ">
          <div className="coursesTableDiv">
            <Table dataSource={id ? courseCompetencies : competencies}>
              <Column
                filterDropdown={getColumnSearchProps("name").filterDropdown}
                filterIcon={getColumnSearchProps("name").filterIcon}
                onFilter={getColumnSearchProps("name").onFilter}
                onFilterDropdownOpenChange={
                  getColumnSearchProps("name").onFilterDropdownOpenChange
                }
                title="Competency Name"
                className="tdDiv title"
                dataIndex="name"
                key="name"
                onCell={(record, index) => {
                  return {
                    onClick: (event) => {
                      navigate(
                        `/dashboard/Admin/add-assesment?id=${record.id}&name=${record.name}`
                      );
                    },
                  };
                }}
                render={getColumnSearchProps("name").render}
              />
              {/* <Column
                filterDropdown={
                  getColumnSearchProps("courseName").filterDropdown
                }
                filterIcon={getColumnSearchProps("courseName").filterIcon}
                onFilter={getColumnSearchProps("courseName").onFilter}
                onFilterDropdownOpenChange={
                  getColumnSearchProps("courseName").onFilterDropdownOpenChange
                }
                title="Course Name"
                dataIndex="courseName"
                key="courseName"
                className="cursorNormal"
                onCell={(record, index) => {
                  return {
                    onClick: (event) => {
                      navigate(
                        `/dashboard/Admin/add-assesment?id=${record.id}`
                      );
                    },
                  };
                }}
                render={getColumnSearchProps("courseName").render}
              /> */}
              {/* <Column
                title="Degree"
                dataIndex="degree"
                key="degree"
                className="cursorNormal"
                onCell={(record, index) => {
                  return {
                    onClick: (event) => {
                      navigate(
                        `/dashboard/Admin/add-assesment?id=${record.id}`
                      );
                    },
                  };
                }}
                render={(text) => <span>{text}</span>}
              /> */}
              <Column
                align="center"
                filterDropdown={
                  getColumnSearchProps("onnet_competency").filterDropdown
                }
                filterIcon={getColumnSearchProps("onnet_competency").filterIcon}
                onFilter={getColumnSearchProps("onnet_competency").onFilter}
                onFilterDropdownOpenChange={
                  getColumnSearchProps("onnet_competency")
                    .onFilterDropdownOpenChange
                }
                title="Competency Type "
                dataIndex="onnet_competency"
                key="onnet_competency"
                className="cursorNormal"
                onCell={(record, index) => {
                  return {
                    onClick: (event) => {
                      navigate(
                        `/dashboard/Admin/add-assesment?id=${record.id}`
                      );
                    },
                  };
                }}
                render={getColumnSearchProps("onnet_competency").render}
              />
              <Column
                title="Tier"
                render={(text, record, index) => (
                  <div className="actionButtons">
                    {/* <Switch/> */}
                    <Select
                      style={{ width: 120, marginRight: "20px" }}
                      defaultValue="Select Tier"
                      value={record.tier ? record.tier : "Select Tier"}
                      onChange={(value) => {
                        if (value) {
                          updateTierAndVisiblityCompetencyFn(
                            {
                              tier: value,
                            },
                            record.id
                          );
                        } else {
                          message.error("Select Tier");
                        }
                      }}
                      options={[
                        {
                          value: 1,
                          label: "1st Tier",
                        },
                        {
                          value: 2,
                          label: "2nd Tier",
                        },
                        {
                          value: 3,
                          label: "3rd Tier",
                        },
                      ]}
                    />
                  </div>
                )}
              />
              <Column
                title="Actions"
                render={(text, record, index) => (
                  <div className="actionButtons">
                    <Tooltip title={"Set Visibility"}>
                      <div>
                        {record.hidden ? (
                          <Tag
                            color="red"
                            onClick={() => {
                              updateTierAndVisiblityCompetencyFn(
                                {
                                  hidden: 0,
                                },
                                record.id
                              );
                            }}
                          >
                            Hidden
                          </Tag>
                        ) : (
                          <Tag
                            color="blue"
                            onClick={() => {
                              updateTierAndVisiblityCompetencyFn(
                                {
                                  hidden: 1,
                                },
                                record.id
                              );
                            }}
                          >
                            Visible
                          </Tag>
                        )}
                      </div>
                    </Tooltip>
                    <button
                      className="editButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        showModal(record);
                      }}
                    >
                      <EditIcon />
                    </button>
                  </div>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
      <AddCompentencyModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        editState={editState}
      />
    </div>
  );
}

export default AddCompetency;
