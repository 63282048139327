import React, { useEffect, useState } from "react";
import ProfessorRatingNavbar from "../Navbar/professorRatingNavbar";
import "./addForm.scss";
import { Input, Select, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addProfessor } from "../../module/features/professor";
import Spinner from "../../Constant/Spinner/Spinnner";
import bg from './../../Assest/addFormBackground.png'
import SearchSchool from "../SearchSchool/SearchSchool";
import FormDiv from "./FormDiv";
const { Option } = Select;

function AddProfessorLanding() {
  const universityList = useSelector(
    (state) => state.universitySlice.universityList
  );
  const courses = useSelector(state => state.coursesSlice.courses)
  const [coursesArr, setCoursesArr] = useState([])
  const user = useSelector(state => state.Authentication.user)
  const [universityArr, setUniversityArr] = useState([]);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    university_OBJECTID: 1,
    professor_name: "",
    department_id: 0,
    department_name: "",
    course_id: ""
  });
  const onChange = (value) => {
    let obj = { ...data };
    obj.university_OBJECTID = value;
    setData(obj);
    console.log(`selected ${value} `);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (universityList) {
      let arr = universityList.map((a, i) => {
        return {
          value: `${a.OBJECTID}`,
          label: `${a.NAME}`,
          ...a,
        };
      });
      setUniversityArr(arr);
    }
  }, [universityList]);
  const callback = () => {
    setData({
      university_OBJECTID: 0,
      professor_name: "",
      department_id: 0,
      department_name: "",
      course_id: ""
    });
    setLoader(false);
  };
  const addProfessorFn = () => {
    console.log(data)
    if (user) {
      if (data.university_OBJECTID && data.professor_name && data.department_id && data.course_id) {

        if (agreeTerms) {

          setLoader(true);
          dispatch(addProfessor({ data: data, callback: callback }));
        } else {
          message.error("Agree to terms and conditon")
        }
      } else {
        message.error('All Fields are required')
      }
    } else {
      message.error("Login to add your professor")
    }
  };
  const renderOptionUniversity = (option) => {
    // Customize the rendering of each option
    return (
      <div className="universityListOpt" onClick={() => console.log(option)}>
        <div>
          <span style={{ marginRight: "8px" }}>{option.label}</span>
          <div>
            <span className="location">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1 1 64-64 64.07 64.07 0 0 1-64 64z"></path>
              </svg>
              &nbsp;
              {option.CITY ? option.CITY + ", " : ""}{" "}
              {option.COUNTRY ? option.COUNTRY : ""}
            </span>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (courses?.length) {
      let arr = courses.map((a, i) => {
        return {
          ...a,
          value: a.id,
          label: a.course,
        }
      })
      setCoursesArr(arr)
    }
  }, [courses])
  return (
    <>
      {/* <div className="comapreDiv">
        <div className="innerCompareDive">
          <SearchSchool />
        </div>
        <div className="innerCompareDive">
          <SearchSchool />
        </div>
      </div> */}
      <div className="addFormPage">

        <div className="addForm">
          {/* <p className='subAddHeading'>Please use the search bar above to make sure that the professor does not already exist at this school.</p> */}
          <div className="addFormDiv">
            <FormDiv />
            <img className="backgroundForm" src={bg} />
          </div>
        </div>
      </div>
    </>

  );
}

export default AddProfessorLanding;
