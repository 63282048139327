import { Modal, Select } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Constant/Spinner/Spinnner";
import { useNavigate } from "react-router-dom";
import {
  addCompetency,
  editCompetency,
  getCompetencies,
} from "../../module/features/competency";
import { addDegree, updateDegree } from "../../module/features/Degree";
import {
  addConcentration,
  getConcentration,
  updateConcentration,
} from "../../module/features/concentration";

function AddDegreeModal({ isModalOpen, handleOk, handleCancel, editState }) {
  const degree = useSelector((state) => state.degreeSlice.degrees);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    degree: "",
  });
  const [loader, setLoader] = useState(false);
  const [degreeArr, setdegreeArr] = useState([]);
  useEffect(() => {
    console.log(degree, "degree");
    if (degree?.length) {
      let arr = degree.map((a, i) => {
        return {
          ...a,
          value: a.id,
          label: a.name,
        };
      });
      console.log(arr, "dasdasdasd");
      setdegreeArr(arr);
    }
  }, [degree]);
  const clearState = () => {
    setData({
      name: "",
    });
  };
  const callback = () => {
    clearState();
    handleOk();
    setLoader(false);
    dispatch(getConcentration());
  };
  const addDegreeFun = () => {
    setLoader(true);
    dispatch(addConcentration({ data: data, callback }));
  };
  useEffect(() => {
    if (editState) {
      setData({
        degree: editState.degree_Concentration.map((a, i) => {
          return a?.degree?.id;
        }),
        name: editState.name,
      });
    } else {
      clearState();
    }
  }, [editState]);
  const editCompetencyFun = () => {
    setLoader(true);
    dispatch(updateConcentration({ data: data, id: editState.id, callback }));
  };

  return (
    <Modal
      className="addCompetencyModal"
      title={editState ? "Edit Concentration" : "Add Concentration"}
      open={isModalOpen}
      footer={false}
      onCancel={() => {
        handleCancel();
        clearState();
      }}
    >
      <div className="form">
        <label
          className="addCourseInput questionInput"
          style={{ width: "100%" }}
        >
          <p>Concentration Name</p>
          <input
            style={{ width: "100%" }}
            placeholder="Concentrations Name"
            value={data.name}
            onChange={(ev) => {
              let obj = { ...data };
              obj.name = ev.target.value;
              setData(obj);
            }}
          />
        </label>
        <label
          className="addCourseInput questionInput"
          style={{ width: "100%" }}
        >
          <p>Degree Name</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            value={data.degree}
            placeholder="Please select"
            onChange={(ev) => {
              console.log(ev, "sadasdsadas");
              let obj = { ...data };
              obj.degree = ev;
              setData(obj);
            }}
            options={degreeArr}
          />
        </label>

        <div className="addCourse">
          <button className="addCompetencyButton cancel" onClick={handleCancel}>
            Cancel
          </button>
          {editState ? (
            <button
              className="addCompetencyButton add"
              onClick={editCompetencyFun}
            >
              {loader ? <Spinner /> : `Edit Concentration`}
            </button>
          ) : (
            <button className="addCompetencyButton add" onClick={addDegreeFun}>
              {loader ? <Spinner /> : `Add Concentration`}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AddDegreeModal;
