import { createAsyncThunk, createSlice, nanoid } from "@reduxjs/toolkit";
import axios from "axios";
import { getCookie } from "../../Constant/Utils";

const initialState = {
  users: [],
};

export const getUsers = createAsyncThunk(
  "getUsersSlice/getUsers",
  async (data) => {
    const token = getCookie("ungradeToken");
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_DEPLOY_BASE_V2_URL}api/admin/user`,
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache, must-revalidate",
          authorization: token,
        },
      };

      const response = await axios.request(config);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getUsersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state, action) => {})
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {});
  },
});

export const { addTodo, removeTodo } = getUsersSlice.actions;

export default getUsersSlice.reducer;
